import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

class NotFound extends PureComponent {
    render() {
        return (
            <div>
                <h2>Sorry, page not found!</h2>
            </div>
        );
    }
}

NotFound.propTypes = {
};

export default NotFound;