import React, {useState, useRef, useEffect} from 'react';
// import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import sha1 from 'crypto-js/sha1';

import * as Colors from '../misc/LightTheme';
import {Neumorphism} from '../misc/Themes';

import {diffChars} from 'diff';

// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
// import Box from '@material-ui/core/Box';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Check from '@material-ui/icons/Check';
import Replay from '@material-ui/icons/Replay';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useLogin} from '../context/LoginContext';
import ListIcon from '@material-ui/icons/List';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';

import CardFlip from '../components/CardFlip';
import ExamplePassages from '../components/ExamplePassages';
import ExampleSettings from '../components/ExampleSettings';

// const Label = styled.label`
//     color: ${Colors.CONTENT_FG};
// `;

// const VerseRef = styled.a`
//     display: inline;
//     color: ${Colors.CONTENT_FG};
//     font-size: 1.2rem;
//     margin-left: 1rem;
// `;
const RedText = styled.div`
    color: ${Colors.ERROR_FG};
    display: inline;
`;
const GreenText = styled.div`
    color: ${Colors.SUCCESS_FG};
    display: inline;
`;
const RegText = styled.div`
    color: ${Colors.CONTENT_FG};
    display: inline;
`;
const DiffBox = styled.div`
    margin: 0;
    font-size: 1.4rem;
    padding: 1rem;
`;
// const SuccessMessage = styled.div`
//     color: ${Colors.SUCCESS_FG};
//     font-weight: bold;
//     font-size: 1.5rem;
//     align: center;
//     text-align: center;
// `;
const CenteredP = styled.p`
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;
    color: #aaa;
`;

const DiffResults = ({results, ...props}) => {
    /*
    return results ? (
            {results.map((chunk, idx) => {
                const k = 'chunk-' + idx;
                if (chunk.added) {
                    return <GreenText key={k}>{chunk.value}</GreenText>;
                } else if (chunk.removed) {
                    return <RedText key={k}>{chunk.value}</RedText>;
                } else {
                    return <RegText key={k}>{chunk.value}</RegText>;
                }
            })}
    ) : null;
    */
    return results ? (
        <DiffBox>
            {results.map((chunk, idx) => {
                const k = 'chunk-' + idx;
                if (chunk.added) {
                    return <GreenText key={k}>{chunk.value}</GreenText>;
                } else if (chunk.removed) {
                    return <RedText key={k}>{chunk.value}</RedText>;
                } else {
                    return <RegText key={k}>{chunk.value}</RegText>;
                }
            })}
        </DiffBox>
    ) : null;
};

const isCorrectAnswer = diffText => {
    const correct =
        diffText !== undefined && diffText !== null && diffText.length === 0;
    return correct;
};

const useStyles = makeStyles(theme => ({
    paper: {
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    correct: {
        color: Colors.SUCCESS_FG,
    },
}));

export default function ExampleForm() {
    const loginData = useLogin();

    const [flipped, setFlippedPane] = useState(null);
    const [diffText, setDiffText] = useState();
    const [verseIdx, setVerseIndex] = useState(0);
    const [text, setText] = useState('');
    const [verses, setVerses] = useState([
        {
            id: sha1(`${Math.random()}`).toString(),
            title: 'John 1:1 (ESV)',
            text: 'In the beginning was the Word, and the Word was with God, and the Word was God.',
            attempts: 0,
            correct: 0,
        },
    ]);
    const classes = useStyles();
    const flipRef = useRef(null);

    const correct = isCorrectAnswer(diffText);
    const doNothing = () => {};
    const getEventValue = evt => evt.target.value;

    const onRetry = () => {
        // verses[verseIdx].attempts += 1;
        // setVerses(verses.slice());
        setText('');
        setDiffText();
    };
    const onNext = () => {
        verses[verseIdx].attempts = verses[verseIdx].attempts + 1;

        let newIndex = verseIdx + 1;
        if (newIndex > verses.length - 1) newIndex = 0;
        setVerses(verses.slice());
        setVerseIndex(newIndex);
        setText('');
        setDiffText();
    };
    const onCheck = () => {
        const reverse = false;
        const userText = text;
        const verseRef = verses[verseIdx].title;
        const verseText = verses[verseIdx].text;
        verses[verseIdx].attempts = verses[verseIdx].attempts + 1;

        let userTextDiff = diffText;
        const re = /\?|!|,|\.|:|'|’|"|;|-/g; // no-useless-escape
        let str1, str2;
        if (reverse) {
            str1 = verseRef.toLowerCase().trim();
            str2 = userText.toLowerCase().trim();
        } else {
            str1 = verseText.toLowerCase().trim().replace(re, '');
            str2 = userText.toLowerCase().trim().replace(re, '');
            str2 = str2.replace(/\s+/g, ' ');
        }
        if (str1 === str2) {
            userTextDiff = [];
            verses[verseIdx].correct = verses[verseIdx].correct + 1;
        } else {
            userTextDiff = diffChars(str1, str2);
        }
        console.log(verses[verseIdx]);
        setVerses(verses.slice());
        setDiffText(userTextDiff);
    };
    const onKeyDown = evt => {
        if (evt.ctrlKey) {
            switch (evt.key) {
                case 'r':
                    onRetry();
                    break;
                case 'n':
                    onNext();
                    break;
                case 'c':
                    onCheck();
                    break;
                default:
                    break;
            }
        } else {
            switch (evt.key) {
                case 'enter':
                    // Situation sensitive 'do next thing'
                    if (!diffText) {
                        onCheck();
                    } else if (correct) {
                        onNext();
                    }
                    evt.preventDefault();
                    break;
                default:
                    break;
            }
        }
    };
    const height = flipRef.current ? flipRef.current.clientHeight : 0;
    console.log(`in render() = ${verses.length}`);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <CardFlip isFlipped={flipped !== null} flipDirection="horizontal">
                <Neumorphism ref={flipRef}>
                    <div className={classes.paper}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Tooltip title="Change Settings">
                                    <IconButton
                                        onClick={() =>
                                            setFlippedPane('settings')
                                        }
                                    >
                                        <SettingsIcon color="action" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography component="h1" variant="h5">
                                    Try It Out
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Edit Passages">
                                    <IconButton
                                        onClick={() =>
                                            setFlippedPane('passages')
                                        }
                                    >
                                        <ListIcon color="action" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {correct && (
                            <Typography
                                component="h1"
                                variant="h5"
                                className={classes.correct}
                            >
                                Correct!
                            </Typography>
                        )}
                        <DiffResults results={diffText} />
                        <form
                            className={classes.form}
                            onSubmit={doNothing}
                            noValidate
                            style={{paddingTop: '1em'}}
                        >
                            <Grid
                                container
                                justify="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <Grid item>
                                    <Typography component="h3" variant="h5">
                                        {verses[verseIdx].title}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Tooltip title={verses[verseIdx].text}>
                                        <HelpIcon color="action" />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <TextField
                                autoFocus
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="text"
                                label={`Type out the text for '${verses[verseIdx].title}'`}
                                multiline
                                rows="4"
                                value={text}
                                onKeyDown={onKeyDown}
                                onChange={evt => setText(getEventValue(evt))}
                            />
                            <Grid item xs>
                                Type as much of the passage that you can
                                remember and when you are done, click{' '}
                                <b>Check</b> to reveal how accurate you were.
                            </Grid>
                            <Grid container justify="center" spacing={2}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={onCheck}
                                    >
                                        <Check /> Check
                                    </Button>
                                    <CenteredP>Ctrl-C</CenteredP>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.submit}
                                        onClick={onRetry}
                                    >
                                        <Replay /> ReTry
                                    </Button>
                                    <CenteredP>Ctrl-R</CenteredP>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.submit}
                                        disabled={verses.length < 2}
                                        onClick={onNext}
                                    >
                                        Next <ChevronRight />
                                    </Button>
                                    <CenteredP>Ctrl-N</CenteredP>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Neumorphism>

                <Neumorphism height={height}>
                    <div className={classes.paper}>
                        {flipped === 'passages' && (
                            <ExamplePassages
                                verses={verses}
                                maxHeight={height}
                                setVerses={setVerses}
                                setFlippedPane={setFlippedPane}
                            />
                        )}
                        {flipped === 'settings' && (
                            <ExampleSettings
                                maxHeight={height}
                                setFlippedPane={setFlippedPane}
                            />
                        )}
                    </div>
                </Neumorphism>
            </CardFlip>
            {/* <ul>
                <li>Figure out the login</li>
                <li>Figure out database</li>
                <li>Setup a blog section</li>
                <li>Start to track the correct/missed tries</li>
                <li>Get the Enter-key working</li>
            </ul> */}
        </Container>
    );
}
