import {Constants} from '../actions';
import {diffChars} from 'diff';

/**
 * The state for list of verses
 */
const initialState = {
    verses: [], // list of verse objects
    // TODO: see notes about verse structure in metrics.js reducer state
    verseListName: '', // human-readable label
    verseFileName: '', // generic file id name
    questionInProgress: false,
    // verseUserText: '',
    // verseUserTextDiff: null,
    verseIdx: null,
    userText: '',
    userTextDiff: null,
};

const getRandomArbitrary = (min, max) =>
    Math.floor(Math.random() * (max - min) + min);

const getVerse = verses => {
    const idx = getRandomArbitrary(0, verses.length);
    return {
        verses: verses,
        questionInProgress: true,
        verseIdx: idx,
        userTextDiff: null,
        userText: '',
    };
};
const getNextVerse = verses => {
    return getVerse(verses);
};
const retryUserText = () => {
    return {
        userTextDiff: null,
        userText: '',
    };
};

export const verses = (state = initialState, action) => {
    switch (action.type) {
        case Constants.GET_VERSES:
            if (!action.verses) {
                return Object.assign({}, initialState);
            }
            return Object.assign({}, state, getVerse(action.verses));
        case Constants.GET_NEXT_VERSE:
            return Object.assign({}, state, {...getNextVerse(state.verses)});
        case Constants.UPDATE_USER_TEXT:
            return Object.assign({}, state, {userText: action.text});
        case Constants.CHECK_USER_TEXT:
            // eslint-disable-next-line
            const reverse = false;
            const {verseIdx} = state;
            const userText = action.text;
            const verseRef = state.verses[verseIdx].ref;
            const verseText = state.verses[verseIdx].text;
            let userTextDiff = null;
            console.log(`verseRef = ${verseRef}`);
            console.log(`verseText = ${verseText}`);
            const re = /\?|!|,|\.|:|'|’|"|;|-/g; // no-useless-escape
            let str1, str2;
            if (reverse) {
                str1 = verseRef.toLowerCase().trim();
                str2 = userText.toLowerCase().trim();
            } else {
                str1 = verseText
                    .toLowerCase()
                    .trim()
                    .replace(re, '');
                str2 = userText
                    .toLowerCase()
                    .trim()
                    .replace(re, '');
                str2 = str2.replace(/\s+/g, ' ');
            }
            if (str1 === str2) {
                userTextDiff = [];
            } else {
                console.log('here');
                console.log('str1 = ', str1);
                console.log('str2 = ', str2);
                userTextDiff = diffChars(str1, str2);
                console.log(userTextDiff);
            }
            return Object.assign({}, state, {userTextDiff: userTextDiff});
        case Constants.RETRY_USER_TEXT:
            return Object.assign({}, state, {...retryUserText()});
        default:
            // console.log('unhandled action: ', action.type);
            return state;
    }
};
