import styled from 'styled-components';
import {createMuiTheme} from '@material-ui/core/styles';

export const Colors = {
    primary: '#D62828',
    secondary: '#003049',
    tertiary: '#F77F00',

    darkSlateBlue: '#257',
    slate: '#9AB',
    lighterBlue: '#8BD',
    lightBlue: '#7BABCB',
    white: '#FFF',
    blue: '#00F',
    offWhite: '#f2f2f2',
    highlightYellow: '#ffffbb',
};
// NOTE: Arsenal is a free font that is as close to Optima (not free) that I
// could find on Google Fonts (https://fonts.google.com/specimen/Arsenal?selection.family=Arsenal)
export const FontFamily = '"Open Sans", sans-serif';
export const LogoFontFamily = '"Marck Script", serif';

// See: https://material-ui.com/customization/palette/
//      https://material-ui.com/customization/default-theme/
//      https://coolors.co/003049-d62828-f77f00-fcbf49-eae2b7
const palette = {
    background: {default: Colors.white},
    primary: {main: Colors.primary},
    secondary: {main: Colors.secondary},
    error: {main: Colors.tertiary},
};
const themeName = 'red blue yellow';
const typography = {
    fontFamily: FontFamily,
};
export const theme = createMuiTheme({palette, themeName, typography});

export const Neumorphism = styled.div`
    /* border-radius: 92px;
    background: #8aedc3;
    box-shadow: -9px 9px 32px #78ceaa, 9px -9px 32px #9cffdc; */
    padding: 2em;
    margin-top: 3em;
    ${props => (props.height ? `height: ${props.height}px;` : null)}
    border-radius: 7px;
    background: #ffffff;
    box-shadow: -18px 18px 48px #bbbbbb, 18px -18px 48px #ffffff;
`;
