import React, {useState} from 'react';
// import {useForm, Controller} from 'react-hook-form';
import fetch from 'cross-fetch';
import PropTypes from 'prop-types';

import sha1 from 'crypto-js/sha1';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import {useLogin} from '../context/LoginContext';
import Message from '../components/Message';
import apiSettings, {objectToFormData} from '../api';
import {throwErrors, isEmptyObj} from '../utils';
import {invalid} from 'moment';

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function CreateAccountFeedback({info, error, loading}) {
    // Shows Loading, Success-Information, and Error-Information messages as
    // needed.
    if (!isEmptyObj(error)) {
        return (
            <Message error header="Account creation failed" content={error} />
        );
    }
    if (!isEmptyObj(info)) {
        return <Message info header="Account Created!" content={info} />;
    }
    return loading ? <LinearProgress /> : null;
}

export default function LoginApp({loginUser}) {
    const loginData = useLogin();
    const classes = useStyles();
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [password2, setPassword2] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [createInfo, setCreateInfo] = useState(null);
    const [createError, setCreateError] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);

    const onCreateAccountToggle = e => {
        e.preventDefault();
        setShowCreate(!showCreate);
    };

    const onLoginSubmit = () => {
        // We are going to sha1 the password here before we ever let it out.
        const passwordHash = sha1(password).toString();
        setLoading(true);
        return fetch(
            '/api/gettoken',
            apiSettings.post(null, {
                body: JSON.stringify({
                    username,
                    passwordHash,
                    returnUser: true,
                }),
            })
        )
            .then(response => response.json())
            .then(json => throwErrors(json))
            .then(json => {
                setLoading(false);
                if (json.success) {
                    loginUser(json.token, json.user);
                    global.window.location.replace('/');
                } else {
                    console.log(json.error);
                    setLoginError(json.error);
                }
            })
            .catch(err => {
                console.error(err);
                // NOTE: might need to make this a generic login error.
                setLoginError('Could not login; please try again.');
            });
    };

    const addInvalidField = (fieldName, invalidFieldsOverride) => {
        const fields = invalidFieldsOverride || invalidFields;
        if (!fields.some(v => v === fieldName)) fields.push(fieldName);
        setInvalidFields(fields);
        return fields;
    };

    const removeInvalidField = (fieldName, invalidFieldsOverride) => {
        let fields = invalidFieldsOverride || invalidFields;
        fields = fields.filter(v => v !== fieldName);
        setInvalidFields(fields);
        return fields;
    };

    const _createFormIsValid = fieldToCheck => {
        // if fieldToCheck is null or empty, check them all. Otherwise, just
        // check the one requested.
        let fields = invalidFields.copyWithin();
        if (!fieldToCheck) {
            fields = [];
            fieldToCheck = ['username', 'email', 'password', 'password2'];
        }

        fieldToCheck.forEach(fieldName => {
            switch (fieldName) {
                case 'username':
                    if (!username || username.length < 3) {
                        setCreateError('Your username is invalid');
                        fields = addInvalidField(fieldName, fields);
                    } else {
                        fields = removeInvalidField(fieldName, fields);
                    }
                    break;
                case 'email':
                    if (!/\w+@\w+\.\w+/.test(email)) {
                        setCreateError('Your email is invalid');
                        fields = addInvalidField(fieldName, fields);
                    } else {
                        fields = removeInvalidField(fieldName, fields);
                    }
                    break;
                case 'password':
                    fields = removeInvalidField(fieldName);
                    if (!password || password.length === 0) {
                        setCreateError('Please specify a password');
                        fields = addInvalidField(fieldName, fields);
                    }
                    break;
                case 'password2':
                    if (!password2 || password2.length === 0) {
                        setCreateError('Please verify your password');
                        fields = addInvalidField(fieldName, fields);
                    }
                    if (password && password === password2) {
                        setCreateError(null);
                        fields = removeInvalidField(fieldName, fields);
                    }
                    break;
                default:
                    break;
            }
        });
        setInvalidFields(fields);
        return fields.length === 0;
    };

    const onCreateSubmit = event => {
        // Need to do some validation...
        // 1. Check that username doesn't already exist.
        // 2. Verify that passwords match.
        // 3. Create unverified account; send email.
        event.preventDefault();
        setCreateError(null);
        if (_createFormIsValid()) {
            if (password === password2) {
                setInvalidFields(removeInvalidField('password2'));
                setLoading(true);
                // NOTE: isomorphic-fetch uses credentials and fetch browser std uses mode.
                console.log({email, username});
                return fetch(
                    '/api/account/create',
                    apiSettings.post(null, {
                        body: JSON.stringify({
                            email,
                            username,
                            passwordHash: sha1(password).toString(),
                        }),
                    })
                )
                    .then(response => {
                        console.log({response});
                        return response.json();
                    })
                    .then(response => {
                        setLoading(false);
                        if (response.success) {
                            setCreateInfo(
                                'Please check your email to activate account.'
                            );
                        } else {
                            setCreateError(
                                response.errorMessage || response.error
                            );
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                        setCreateError('Unknown error creating account');
                    });
            } else {
                setInvalidFields(addInvalidField('password2'));
                setCreateError("Passwords didn't match. Re-enter them.");
            }
        }
        return false;
    };

    const getEventValue = event => event.target.value;

    return (
        <div className="login-form">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    {/* <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar> */}
                    {!showCreate && (
                        <React.Fragment>
                            <Typography component="h1" variant="h5">
                                Sign In
                            </Typography>
                            <form
                                action="#"
                                className={classes.form}
                                onSubmit={onLoginSubmit}
                                noValidate
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Email Address"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    value={username}
                                    onChange={evt =>
                                        setUsername(getEventValue(evt))
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={evt =>
                                        setPassword(getEventValue(evt))
                                    }
                                />
                                {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                /> */}
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    fullWidth
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    {/* <Grid item xs>
                                        <Link href="#" variant="body2">Forgot password?</Link>
                                    </Grid> */}
                                    <Grid item>
                                        <Link
                                            href="#"
                                            variant="body2"
                                            onClick={onCreateAccountToggle}
                                        >
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </React.Fragment>
                    )}
                    {showCreate && (
                        <React.Fragment>
                            <form
                                className={classes.form}
                                onSubmit={onCreateSubmit}
                                noValidate
                            >
                                <CreateAccountFeedback
                                    info={createInfo}
                                    error={createError}
                                    loading={loading}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Username"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    onChange={evt =>
                                        setUsername(getEventValue(evt))
                                    }
                                    error={
                                        invalidFields.indexOf('username') > -1
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    autoComplete="email"
                                    onChange={evt =>
                                        setEmail(getEventValue(evt))
                                    }
                                    error={invalidFields.indexOf('email') > -1}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Password"
                                    name="password"
                                    type="password"
                                    autoComplete="password"
                                    onChange={evt =>
                                        setPassword(getEventValue(evt))
                                    }
                                    error={
                                        invalidFields.indexOf('password') > -1
                                    }
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Repeat Password"
                                    name="password2"
                                    type="password"
                                    autoComplete="password2"
                                    onChange={evt =>
                                        setPassword2(getEventValue(evt))
                                    }
                                    error={
                                        invalidFields.indexOf('password2') > -1
                                    }
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Create
                                </Button>
                                <Grid container>
                                    {/* <Grid item xs>
                                        <Link href="#" variant="body2">Forgot password?</Link>
                                    </Grid> */}
                                    <Grid item>
                                        <Link
                                            href="#"
                                            variant="body2"
                                            onClick={onCreateAccountToggle}
                                        >
                                            <ChevronLeft /> {'Back to Login'}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </React.Fragment>
                    )}
                </div>
            </Container>
        </div>
    );
}
LoginApp.propTypes = {
    routeProps: PropTypes.object,
    loginUser: PropTypes.func,
};

/*
export class LoginAppOld extends React.Component {

    onInputChange = (e, { name, value }) => this.setState({ [name]: value});
    onCreateFormInputChange = (e, { name, value }) => {
        this.setState({ [name]: value }, () => {
            this._createFormIsValid([name]);
        });
    }

    render() {
        const showCreate = this.state.showCreate;
        return (
            <div className='login-form'>
            <style>{`
              body > div,
              body > div > div,
              body > div > div > div.login-form {
                height: 100%;
              }
            `}</style>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 450 }}>
              {!showCreate && (
                <div>
                <Header as='h2' color='black' textAlign='center'>Log-in to your account</Header>
                {!_isEmpty(this.state.loginError) && (
                <Message
                    error
                    header='Login failed'
                    content={this.state.loginError}
                />
                )}
                {this.state.loading && (
                    <Loader active inline='centered' />
                )}
                <Form size='small' onSubmit={this.onLoginSubmit}>
                  <Segment>
                      <TextField id="username" label="E-mail address" />
                    <Form.Input
                        fluid icon='user'
                        iconPosition='left'
                        placeholder='E-mail address'
                        name='username'
                        onChange={this.onInputChange}
                    />
                    <Form.Input
                      fluid
                      icon='lock'
                      iconPosition='left'
                      placeholder='Password'
                      type='password'
                      name='password'
                      onChange={this.onInputChange}
                    />
                    <Form.Group inline>
                        <Form.Button color='twitter' fluid size='large' primary>Login</Form.Button>
                        <Form.Button onClick={this.onCreateAccountToggle}>Create Account</Form.Button>
                    </Form.Group>
                  </Segment>
                </Form>
                </div>
              )}
              {showCreate && (
                  <div>
                    <Header as='h2' color='black' textAlign='center'>Create Account</Header>
                    {!_isEmpty(this.state.createError) && (
                        <Message
                            error
                            header='Account creation failed'
                            content={this.state.createError}
                        />
                    )}
                    {!_isEmpty(this.state.createInfo) && (
                        <Message
                            info
                            header='Account Created!'
                            content={this.state.createInfo}
                        />
                    )}
                    {this.state.loading && (
                        <Loader active inline='centered' />
                    )}
                    <Form size='small' onSubmit={this.onCreateSubmit}>
                        <Segment>
                        <Form.Input label='Username' name='username'
                            placeholder='enter any name'
                            error={this.state.invalidFields.indexOf('username') > -1}
                            onChange={this.onCreateFormInputChange} />
                        <Form.Input label='Email' name='email'
                            placeholder='enter email address'
                            error={this.state.invalidFields.indexOf('email') > -1}
                            onChange={this.onCreateFormInputChange} />
                        <Form.Input label='Password' name='password'
                            type='password' placeholder='password'
                            onChange={this.onCreateFormInputChange} />
                        <Form.Input label='Repeat Password' name='password2'
                            type='password' placeholder='type password again'
                            error={this.state.invalidFields.indexOf('password2') > -1}
                            onChange={this.onCreateFormInputChange} />
                        <Form.Group inline>
                            <Form.Button primary disabled={this.state.invalidFields.length > 0}>Create</Form.Button>
                            <Form.Button onClick={this.onCreateAccountToggle}><FaBack/> Back to Login</Form.Button>
                        </Form.Group>
                        </Segment>
                    </Form>
                </div>
              )}
              </Grid.Column>
            </Grid>
            </div>
        );
    }
}

*/
