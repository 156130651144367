import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {},
}));

const MessageHeader = styled.div`
    font-weight: 700;
`;

function Message(props) {
    const classes = useStyles();

    let severity = 'info';
    if (props.error) severity = 'error';

    return (
        <Alert severity={severity} className={classes.root}>
            <MessageHeader>{props.header}</MessageHeader> {props.content}
        </Alert>
    );
}
Message.propTypes = {
    error: PropTypes.bool,
    info: PropTypes.bool,
    header: PropTypes.string,
    content: PropTypes.string,
};

export default Message;
