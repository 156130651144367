import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter as Router} from 'react-router-dom';

import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import './index.css';
import WebApp from './containers/WebApp';
import {unregister} from './registerServiceWorker';
import appReducer from './reducers';

const loggerMiddleware = createLogger();
const store = createStore(
    appReducer,
    applyMiddleware(
        thunkMiddleware, // lets us dispatch() functions
        loggerMiddleware // neat middleware that logs actions
    )
);
// eslint-disable-next-line no-undef
if (document.getElementById('root')) {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <Router>
                    <WebApp />
                </Router>
            </Provider>
        </React.StrictMode>,
        // eslint-disable-next-line no-undef
        document.getElementById('root')
    );
}
unregister(); // uninstalls service workers if found
