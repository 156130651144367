// NOTE: pretty sure these are only used for the semantic ui theme...
import * as Colors from './colors';

export const HEADER_BG = Colors.SOFT_WHITE;
export const HEADER_FG = Colors.DEEP_PURPLE;
export const HEADER_BORDER = Colors.LIGHT_BLUE;

export const CONTENT_BG = Colors.HARD_WHITE;
export const CONTENT_FG = Colors.MED_PURPLE;

export const FOOTER_BG = Colors.SOFT_WHITE;
export const FOOTER_FG = Colors.DEEP_PURPLE;

export const ERROR_FG = Colors.DARK_RED;
export const SUCCESS_FG = '#239E25';
