import { Constants } from '../actions';

/**
 * The state for global and atomic metrics
 */
const initialState = {
    global: {
        lastAttemptDate: null,
        lastVerseList: null,
        overallCorrect: 0,
        overallAttempted: 0,
    },
    verseMetrics: {
        // TODO: need some thought here. we should probably have an id for every
        // verse, but accounting for apocrypha, omitted, added verses is
        // something we need to be thoughtful about. Might be best to not try to
        // hash to some formula but rather hash based on textual title, etc.
        // that way it's not trying to have an awareness about biblical
        // structure, or correctness, etc. and just takes what you give it. This
        // might also be good if you have the same verse, but from different
        // translations that you are trying to memorize. Let's call that a
        // variant text.
    },
};

export const metrics = (state = initialState, action) => {
    // let m;
    switch (action.type) {
    case Constants.SAVE_METRICS:
        // m = action.metrics;
        // TODO: implement metrics logic
        return state;
    default:
        return state;
    }
};
export default metrics;