import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const ExampleBackPanel = ({
    title,
    extraButton,
    maxHeight,
    setFlippedPane,
    ...props
}) => {
    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Tooltip title="Back">
                        <IconButton
                            onClick={() => setFlippedPane(null)}
                            size="small"
                        >
                            <ArrowBackIcon color="secondary.light" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography component="h1" variant="h5">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>{extraButton}</Grid>
            </Grid>
            <Container>{props.children}</Container>
        </React.Fragment>
    );
};
ExampleBackPanel.propTypes = {
    title: PropTypes.string,
    extraButton: PropTypes.object,
    setFlippedPane: PropTypes.func,
};
export default ExampleBackPanel;
