import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import sha1 from 'crypto-js/sha1';

import ExampleBackPanel from './ExampleBackPanel';
// import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import {makeStyles, withStyles} from '@material-ui/core/styles';

// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const getEventValue = evt => evt.target.value;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        // border: '1px solid #ddd',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(255,200,200,0.2) 0%, rgba(0,0,0,0) 100%)',
    },
}));

const PassageCard = ({verse, editing, onSave}) => {
    const [editable, onEdit] = useState(verse.title === null || editing);
    const [text, setText] = useState(verse.text);
    const [title, setTitle] = useState(verse.title);

    const onEditSaveClicked = () => {
        onEdit(!editable);
        if (editable) {
            // If the current state was editable, and we are leaving that, it's
            // a save, so update the verse.
            verse.text = text;
            verse.title = title;
            onSave(verse);
        }
    };

    return (
        <Card variant="outlined" style={{height: '100%'}}>
            <CardContent>
                {!editable && (
                    <Typography component="h2" variant="h5" gutterBottom>
                        {verse.title}
                    </Typography>
                )}
                {editable && (
                    <TextField
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="title"
                        label="Title"
                        rows="1"
                        value={title}
                        onChange={evt => setTitle(getEventValue(evt))}
                    />
                )}
                {editable && (
                    <TextField
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        name="text"
                        label="Text"
                        multiline
                        rows="3"
                        value={text}
                        onChange={evt => setText(getEventValue(evt))}
                    />
                )}
                {!editable && (
                    <Typography variant="body2" component="p">
                        {text}
                    </Typography>
                )}
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onEditSaveClicked}
                >
                    {editable ? 'Save' : 'Edit'}
                </Button>
            </CardActions>
        </Card>
    );
};

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const ExamplePassages = ({verses, setVerses, maxHeight, ...props}) => {
    const [manageIdx, setManageIdx] = useState(0);
    const [editing, setEditing] = useState(null); // null or verse object

    useEffect(() => {
        return () => {};
    }, [verses]);

    const onDeleteVerse = verse => {
        setVerses(
            verses.filter(passage => {
                return passage.id !== verse.id;
            })
        );
    };

    const onEditVerse = verse => {
        setEditing(verse);
    };

    const onCreateVerse = () => {
        verses.unshift({
            id: sha1(`${Math.random()}`).toString(),
            title: null,
            text: null,
            attempts: 0,
            correct: 0,
        });
        setVerses(verses.slice());
    };

    const CreateVerse = () => {
        return (
            <Tooltip title="Add New Passage">
                <IconButton onClick={onCreateVerse}>
                    <AddIcon color="secondary.light" />
                </IconButton>
            </Tooltip>
        );
    };
    const useStyles = makeStyles({
        root: {
            width: '100%',
        },
        container: {
            maxHeight: maxHeight - 100,
        },
    });
    const classes = useStyles();

    // Return mastery as a digit string percentage, '1', '23' for 1%, 23%, etc.
    const getMastery = passage => {
        let mastery = '0';
        if (passage.attempts > 0) {
            mastery = Number(
                (passage.correct / passage.attempts) * 100
            ).toFixed(0);
        }
        return mastery;
    };
    const onSave = () => {
        setEditing(null);
    };

    return (
        <ExampleBackPanel
            title="Manage Passages"
            {...props}
            extraButton={<CreateVerse />}
        >
            <Paper className={classes.root}>
                {editing && (
                    <PassageCard
                        editing={true}
                        verse={editing}
                        onSave={onSave}
                    />
                )}
                {!editing && (
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell align="center">
                                        Text
                                    </StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                    <StyledTableCell align="right">
                                        Attempts
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Correct
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        Mastery
                                    </StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {verses.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.title}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.text}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Edit">
                                                <IconButton
                                                    onClick={onEditVerse.bind(
                                                        this,
                                                        row
                                                    )}
                                                    size="small"
                                                >
                                                    <EditIcon
                                                        className={
                                                            classes.title
                                                        }
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.attempts || '0'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {row.correct || '0'}
                                        </TableCell>
                                        <TableCell align="right">
                                            {getMastery(row)}%
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Delete">
                                                <IconButton
                                                    onClick={onDeleteVerse.bind(
                                                        this,
                                                        row
                                                    )}
                                                    size="small"
                                                >
                                                    <DeleteIcon color="secondary.light" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </ExampleBackPanel>
    );
};
ExamplePassages.propTypes = {
    verses: PropTypes.object,
    setVerses: PropTypes.func,
};

export default ExamplePassages;
