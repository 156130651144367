import fetch from 'cross-fetch';
import {throwErrors} from './utils';
import apiSettings from './api';

export const Constants = {}; // placeholder for constants defined further down.

Constants.GET_VERSES = 'get verses';
export function getVerses() {
    const action = data => ({
        type: Constants.GET_VERSES,
        verses: data.verses,
    });
    return dispatch => {
        return fetch('/api/passages', apiSettings.get())
            .then(response => response.json())
            .then(json => throwErrors(json))
            .then(json => dispatch(action(json)))
            .catch(err => console.log(err));
    };
}

Constants.GET_NEXT_VERSE = 'get next verse';
export function getNextVerse() {
    const action = () => ({
        type: Constants.GET_NEXT_VERSE,
    });
    return dispatch => dispatch(action());
}

Constants.RETRY_USER_TEXT = 'retry user text';
export function retryUserText() {
    const action = t => ({
        type: Constants.RETRY_USER_TEXT,
    });
    return dispatch => dispatch(action());
}

Constants.UPDATE_USER_TEXT = 'update user text';
export function updateUserText(text) {
    const action = t => ({
        type: Constants.UPDATE_USER_TEXT,
        text: t,
    });
    return dispatch => dispatch(action(text));
}

Constants.CHECK_USER_TEXT = 'check user text';
export function checkUserText(txt) {
    const action = text => ({
        type: Constants.CHECK_USER_TEXT,
        text: text,
    });
    return dispatch => dispatch(action(txt));
}

Constants.SAVE_METRICS = 'save metrics';
export function saveMetrics() {
    const action = () => ({
        type: Constants.SAVE_METRICS,
    });
    return dispatch => dispatch(action());
}
