// NOTE: pretty sure these are only used for the semantic ui theme...
export const SOFT_WHITE = '#EEE';
export const HARD_WHITE = '#FFF';
export const DEEP_PURPLE = '#191936';
export const MED_PURPLE = '#25254B';
export const GOLDEN_YELLOW = '#F9D400';

export const LIGHT_RED = '#FDF2F4';
export const DARK_RED = '#FF457E';

export const LIGHT_GREEN = '#92B793';
export const DARK_GREEN = '#236E25';

export const LIGHT_BLUE = '#0093DC';
