import React from 'react';
import PropTypes from 'prop-types';

// import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
// import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ExampleBackPanel from './ExampleBackPanel';

export const ExampleSettings = props => {
    return (
        <ExampleBackPanel title="Settings" {...props}>
            <p>settings are really really fun</p>
        </ExampleBackPanel>
    );
};

// ExampleSettings.propTypes = {
//     setFlippedPane: PropTypes.func,
// };
export default ExampleSettings;
