import React, {useContext} from 'react';

const {Provider, Consumer} = React.createContext();

class LoginProvider extends React.Component {
    render() {
        const login = this.props.value;
        return <Provider value={login}>{this.props.children}</Provider>;
    }
}

// Hook for our functional components that need to do something outside of JSX
const useLogin = () => {
    return useContext(Consumer);
};

export {LoginProvider, Consumer as LoginConsumer, useLogin};
