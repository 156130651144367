/**
 * Quick utility to append url encoded key-value pairs to existing url string.
 * @param {string} url - exisitng url
 * @param {string} name - name of param
 * @param {any} value - any value appropriate for url encoding
 * @returns {string} - Updated url string with params appended.
 */
export function appendURLQueryArg(url, name, value) {
    // NOTE: we don't do anything to validate what is in url, other than to see
    // if it's already begun (by testing for ?) to encode parameters. URL could
    // be junk in other words; we don't care.
    if (value === undefined || name === undefined) return url;
    const url2 = url ? url : '/';
    const paramString = encodeURI(`${name}=${value}`);
    const connector = url2.match(/\?/) ? '&' : '?';
    return url2 + connector + paramString;
}

// isEmptyObj - Quick function to test for empty object rather than using lodash.
export const isEmptyObj = o =>
    !o || (Object.keys(o).length === 0 && o.constructor === Object);

export const throwErrors = json => {
    if (!json) throw new Error('Unknown Error');
    if (json.status !== undefined && json.status !== 'ok') {
        if (json.error) {
            console.log(json);
            throw new Error(json.error);
        } else throw new Error('Unknown Error');
    }
    if (json.success !== undefined && !json.success) {
        if (json.error) {
            console.log(json);
            throw new Error(json.error);
        } else throw new Error('Unknown Error');
    }
    return json;
};
