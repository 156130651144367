import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Drawer,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Grid,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ListIcon from '@material-ui/icons/List';

// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';

import {useLogin} from '../context/LoginContext';
import {LogoFontFamily} from '../misc/Themes';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontWeight: 700,
        fontFamily: LogoFontFamily,
        fontSize: '2.5rem',
        textTransform: 'none',
    },
}));

const Logo = styled.img`
    padding: 0.5rem 0 0 0;
    margin: 0;
    width: 2.5em;
    flex-grow: 0;
`;

export default function MainToolbar(props) {
    const loginData = useLogin();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    // loginData looks like: {username: '...', password: '...', user: {}, authToken: '...'}
    const auth = loginData && loginData.authToken;
    console.log({loginData});
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = event => {
        setAnchorEl(null);
    };
    const toggleDrawer = (side, open) => event => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({...state, [side]: open});
    };
    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem button key={auth ? 'Account' : 'Login'}>
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={auth ? 'Account' : 'Login'} />
                </ListItem>
                <ListItem button key="settings">
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button key="passages">
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Passages" />
                </ListItem>
                {/* {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))} */}
            </List>
        </div>
    );

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer('left', true)}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                    {sideList('left')}
                </Drawer>
                <Grid justify={'center'} alignItems={'center'} container>
                    <Button color="inherit" to="/" component={Link}>
                        <Grid style={{justifySelf: 'flex-start'}} item>
                            <Logo src="/static/img/logo.svg" alt="" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>
                                {props.title}
                            </Typography>
                        </Grid>
                    </Button>
                </Grid>
                {auth && (
                    <div>
                        <IconButton
                            aria-label={loginData.username}
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>
                                My Listings
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleClose}>Logout</MenuItem>
                        </Menu>
                    </div>
                )}
                {!auth && (
                    <Button color="inherit" to="/login" component={Link}>
                        Login
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
}

// export default class MainToolbar extends Component {

//     static propTypes = {
//         accountListings: PropTypes.array, // optional
//     }

//     onLogout = (evt) => {
//         evt.preventDefault();
//         this.props.logoutUser(this.props.history);
//     }

//     render() {
//         const {accountListings} = this.props;
//         return (
//           <PSMenu fixed="top" inverted>
//             <LoginConsumer>
//                 {(login) => {
//                     const haveUser = !isEmptyObj(login.user);
//                     const name = haveUser ? login.user.username || login.user.email : '';
//                     return (
//                         <Container>
//                         <PSMenuItem as={Link} to='/' header>
//                             <Image
//                             size='mini'
//                             src="/static/img/psLogoSolidWhite.svg"
//                             style={{ height: '3.4em' }}
//                             />
//                             Paddle Swap
//                         </PSMenuItem>
//                         {!haveUser && (
//                             <PSMenuItem as={Link} to='/login'>Login or Create Account</PSMenuItem>
//                         )}
//                         {haveUser && (
//                             <PSDropdown item simple text={name}>
//                                 <PSDropdownMenu>
//                                 <PSDropdownItem as={Link} to='/listing/'>Add New Listing</PSDropdownItem>
//                                 <PSDropdownItem>
//                                     <i className='dropdown icon' />
//                                     <span className='text'>Your Listings</span>
//                                     <PSDropdownMenu>
//                                     {accountListings && accountListings.map( l => {
//                                         const key = 'listings_' + l._id;
//                                         return (<PSDropdownItem key={key} as={Link} to={`/listing/${l._id}`}>{l.title}</PSDropdownItem>);
//                                     })}
//                                     {!accountListings && (<PSDropdownItem>None</PSDropdownItem>)}
//                                     </PSDropdownMenu>
//                                 </PSDropdownItem>
//                                 <Dropdown.Divider />
//                                 <PSDropdownItem as={Link} to='/account/'>Settings</PSDropdownItem>
//                                 <PSDropdownItem as={Link} to='#' onClick={this.onLogout}>Log out</PSDropdownItem>
//                                 </PSDropdownMenu>
//                             </PSDropdown>
//                         )}
//                         </Container>
//                     );
//                 }}
//             </LoginConsumer>
//           </PSMenu>
//         );
//     }
// }
