import React from 'react';
import {Route, Switch} from 'react-router-dom';
// import asyncComponent from "../components/AsyncComponent";
import NotFound from '../containers/NotFound';
// import WebLayout from '../components/WebLayout';
import Example from '../containers/Example';
import Login from '../containers/Login';

const Routes = props => (
    <Switch>
        <Route
            path="/"
            exact
            render={routeProps => (
                <Example routeProps={routeProps} {...props} />
            )}
        />
        <Route
            path="/login"
            exact
            render={routeProps => <Login routeProps={routeProps} {...props} />}
        />
        <Route path="" component={NotFound} />
    </Switch>
);
export default Routes;
