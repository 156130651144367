import Cookies from 'js-cookie';

// cross-fetch config
const baseSettings = {
    mode: 'cors',
    credentials: 'include',
    redirect: 'follow',
    referrer: 'no-referrer',
    headers: {'Content-Type': 'application/json'},
    cache: 'no-cache',
};

export function getAuthCookie() {
    return Cookies.get('auth-token');
}
function getAuthToken(a) {
    return a || getAuthCookie();
}

function addAuthHeader(settings, authToken) {
    const at = getAuthToken(authToken);
    if (at) {
        settings.headers.authorization = 'Bearer ' + at;
    }
    return settings;
}

const apiSettings = {
    post: (authToken, options) => {
        let s = {
            method: 'POST',
            ...baseSettings,
        };
        if (options) s = Object.assign(s, options);
        return addAuthHeader(s, authToken);
    },
    get: (authToken, options) => {
        let s = {
            method: 'GET',
            ...baseSettings,
        };
        if (options) s = Object.assign(s, options);
        return addAuthHeader(s, authToken);
    },
};

export function objectToFormData(obj) {
    var formData = new FormData();
    Object.keys(obj).forEach(k => {
        if (Array.isArray(obj[k])) {
            obj[k].forEach(opt => {
                formData.append(k + '[]', opt);
            });
        } else {
            formData.append(k, obj[k]);
        }
    });
    return formData;
}

export default apiSettings;
