import React from 'react';
// import createHistory from 'history/createBrowserHistory';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import {LoginProvider} from '../context/LoginContext';
import Typography from '@material-ui/core/Typography';
import {theme as srTheme} from '../misc/Themes';
import {
    getVerses,
    getNextVerse,
    retryUserText,
    updateUserText,
    checkUserText,
    saveMetrics,
} from '../actions';

import Routes from '../router/Routes';
import MainToolbar from '../components/MainToolbar';

const mapStoreStateToProps = state => {
    return {
        metrics: state.metrics,
        verses: state.verses,
        questionInProgress: state.questionInProgress,
        verseIdx: state.verseIdx,
        userText: state.userText,
        userTextDiff: state.userTextDiff,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getVerses: () => dispatch(getVerses()),
        onNextVerse: () => dispatch(getNextVerse()),
        onRetryUserText: () => dispatch(retryUserText()),
        onUpdateUserText: text => dispatch(updateUserText(text)),
        onCheckUserText: text => dispatch(checkUserText(text)),
        saveMetric: () => dispatch(saveMetrics()),
    };
};

function Copyright({business}) {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            Copyright © {business} {new Date().getFullYear()}.
        </Typography>
    );
}

function App(props) {
    const style = {
        margin: '0.5rem 0',
        padding: 0,
        border: 0,
        backgroundColor: '#FFF',
    };
    const name = 'ScriptRecall';
    return (
        <ThemeProvider theme={srTheme}>
            <LoginProvider value={props.login}>
                <MainToolbar title={name} {...props} />
                <div style={style}>
                    <Routes {...props} />
                </div>
                <Copyright business={name} />
            </LoginProvider>
        </ThemeProvider>
    );
}

export default withRouter(
    connect(mapStoreStateToProps, mapDispatchToProps)(App)
);
